import React, { useState } from 'react'
import { CSVLink } from 'react-csv';
import AppicationPage from '../pages/AppicationPage';
import BrandDashboard2 from '../pages/brandTable';
import BrandDashboard1 from '../pages/influencerTable';
// import BrandPage from '../pages/BrandForm';
import BrandFormInternal from '../pages/BrandFormInternal';
import BrandTable1 from '../pages/brandTable1';
import InfluencerDeliverblesTable from '../pages/infDeliverables';
import InfluencerConformationTable from '../pages/infConfTable';
import InfluencerConformationTableExecutive from '../pages/infConfTableExecutive';
import ApproveCampaignPageInternal from '../pages/ApprovecampaignInternal';
import logo from '../logo192.png';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import BrandCampaignForm from '../pages/BrandCampaignFormBrand';

library.add(fas);
const ExecutiveDashboard = () => {

    // menu visiblity set
    const [menuVisible, setMenuVisible] = useState(false);

    const handleToggleMenu = () => {
        setMenuVisible(!menuVisible);
    };

    //create new campaign 



    const [PhoneNumber, setPhoneNumber] = useState("");
    // const [WhatsappNumber, setWhatsappNumber] = useState("");
    // const [UserName, setUserName] = useState("")

    //mobile number validation regx
    //const [MobileNumber, setmobilenumber] = useState("");
    const [Email, setemail] = useState();
    const [MobileNumber, setmobilenumber] = useState("");
    //mobile number validation regx
    let hasValNumber = /^[0-9]{1,10}$/.test(PhoneNumber || MobileNumber);

    //email validatin regx
    let hasValEmail = /^[a.-zA.-Z0.-9]+@[a.-zA.-Z0.-9]+\.[A-Za-z]+$/.test(Email);


    // create new campaignForm
    const [showCreateCampaignForm, setShowCreateCampaignForm] = useState(false);
    // const [selectedCampaign, setSelectedCampaign] = useState(null);
    const handleCreateNewCampaignClick = () => {
        setShowCreateCampaignForm(true);
        setShowUserRoles(false);
        setShowInfluencerApplications(false);
        setshowShortlistedInfluencers(false);
    };

    // Influencer Applications 
    const [showInfluencerApplications, setShowInfluencerApplications] = useState(false);
    const handleInfluencerApplicationsClick = () => {
        setShowInfluencerApplications(true);
        setShowCreateCampaignForm(false);
        setshowShortlistedInfluencers(false);
        setShowUserRoles(false);
    }
    const [showBrandApplications, setShowBrandApplications] = useState(false);
    const handleBrandApplicationsClick = () => {
        setShowBrandApplications(true);
        setShowCreateCampaignForm(false);
        setshowShortlistedInfluencers(false);
        setShowUserRoles(false);
    }


    // Shortlisted Influencers
    const [showShortlistedInfluencers, setshowShortlistedInfluencers] = useState(false);
    const handleShortlistedInfluencersClick = () => {
        setshowShortlistedInfluencers(true);
        setShowCreateCampaignForm(false);
        setShowInfluencerApplications(false);
        setShowUserRoles(false);
    }



    const [selectedOption, setSelectedOption] = useState('');
    const [showC1Table, setShowC1Table] = useState(false);
    const [showC2Table, setShowC2Table] = useState(false);
    const [showC3Table, setShowC3Table] = useState(false);

    const handleOptionSelect = (option) => {
        setSelectedOption(option);

        switch (option) {
            case 'option1':
                setShowC1Table(true);
                setShowC2Table(false);
                setShowC3Table(false);
                break;
            case 'option2':
                setShowC1Table(false);
                setShowC2Table(true);
                setShowC3Table(false);
                break;
            case 'option3':
                setShowC1Table(false);
                setShowC2Table(false);
                setShowC3Table(true);
                break;
            default:
                setShowC1Table(false);
                setShowC2Table(false);
                setShowC3Table(false);
        }
    };

    const handleC1Click = () => {
        console.log('C1 clicked');
        handleOptionSelect('option1');
    };

    const handleC2Click = () => {
        console.log('C2 clicked');
        handleOptionSelect('option2');
    };

    const handleC3Click = () => {
        console.log('C3 clicked');
        handleOptionSelect('option3');
    };

    // table
    // Sample table data
    const tableData = [
        ["Data 1", "Data 2", "data3"],

        // Add more rows and data
    ];

    // Function to generate CSV data from the tableData
    const generateCSVData = () => {
        const headers = Array.from(document.querySelectorAll('.table th')).map(th => th.innerText);// Add more headers if needed
        const csvData = [headers, ...tableData];
        return csvData;
    };
    // status set 
    const statusOptions = ["Selected", "Rejected", "On Hold"];
    // add shipment
    // const [selectedRows, setSelectedRows] = useState([]);
    const [shipmentStatus, setShipmentStatus] = useState({});

    const handleCheckboxChange = (index, status) => {
        setShipmentStatus((prevShipmentStatus) => ({
            ...prevShipmentStatus,
            [index]: status,
        }))
    };

    // Add Tracking ID
    const [trackingIds, setTrackingIds] = useState({});
    const handleTrackingIdChange = (index, trackingId) => {
        setTrackingIds((prevTrackingIds) => ({
            ...prevTrackingIds,
            [index]: trackingId,
        }));
    };

    // Handler for status change
    const handleStatusChange = (index, selectedStatus) => {
        // Add logic to handle the status change (e.g., update the data or make an API call)
        console.log(`Status changed for row ${index + 1}: ${selectedStatus}`);
    };

    // Option to approve deliverables
    const handleApproval = (index) => {
        // Add logic to handle the approval ( update the data or make an API call)
        console.log(`Deliverables approved for row ${index + 1}`);
    };
    // filters

    const [filterStatus, setFilterStatus] = useState('all');


    const data = generateCSVData();
    const filteredData = data.filter(item => {
        if (filterStatus === 'all') return true;
        return item.status === filterStatus; // Replace 'status' with the actual property that indicates the status
    });

    const [showUserRoles, setShowUserRoles] = useState(false);


    // Handle User Roles link click
    const handleUserRolesClick = () => {
        setShowUserRoles(true);
        setShowAllCampaign(false);
        setShowCreateCampaignForm(false);

    };
    //invite form  
    const [showInviteForm, setShowInviteForm] = useState(false);
    const handleInviteTeamMembersClick = () => {
        setShowInviteForm(true);
        setShowUserRoles(false);
        setShowCreateCampaignForm(false);
        setShowInfluencerApplications(false);
        setshowShortlistedInfluencers(false);
    };

    const [showDeliverables, setshowDeliverables] = useState(false);
    const handleDeliverbralesClick = () => {
        setshowDeliverables(true);
        setShowCreateCampaignForm(false);
        setShowInfluencerApplications(false);
        setShowUserRoles(false);
    }
    const [showConformation, setshowConformation] = useState(false);
    const handleConformationClick = () => {
        setshowConformation(true);
        setShowCreateCampaignForm(false);
        setShowInfluencerApplications(false);
    }
    const [showAllCampaign, setShowAllCampaign] = useState(false);
    const handleShowAllCampignClick = () => {
        setShowAllCampaign(true);  // Set the state to show the "Approved Campaigns" page
        setShowCreateCampaignForm(false);
        setShowInfluencerApplications(false);
        setshowShortlistedInfluencers(false);
    };

    const [isActive, setIsActive] = useState(false);

    const toggleMenu = () => {
        setIsActive(!isActive);
    };


    const handleMouseEnter = () => {
        setIsActive(true);
    };

    const handleMouseLeave = () => {
        setIsActive(false);
    };

    return (
        <nav className="navbar" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
                {/* Sidebar */}
                <a className="navbar-item" >
                    {/* <span className="icon mr-3">
                    <img className="ml-5" src="./images/hobo.jpeg" alt="Menu Icon" />
                </span> */}
                </a>
            </div>
            <div >

                <div className={`hamburger ${isActive ? 'is-active' : ''} `} onClick={toggleMenu}>
                    {/* <i className="fas fa-bars is-32x32" style={{ marginLeft: "-15px" }}></i> */}
                    {/* <figure className="image is-32x32" style={{ marginLeft: "-29px" }}>
                        <img src={logo} alt="Logo" />
                    </figure> */}
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </div>

                {/* <div className= has-background-info' style={{marginRight:"10rem"}}> */}
                <div className={`navbar-menu ${isActive ? 'is-active' : ''}`} style={{ backgroundColor: 'dark-blue' }}>
                    <aside className={` menu  ${isActive ? '' : 'is-hidden'} overflow-hidden`}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <ul className="menu-list d-flex flex-row-reverse">
                            <div className=' has-text-left ml-3' >
                                <figure className="image is-32x32" >
                                    <img src={logo} alt="Logo" />
                                </figure>
                                {/* <li className="menu-label " style={{ marginTop: "1rem", marginLeft: "4px" }}>
                                Executive Dashboard
                            </li> */}
                                <li className='my-5 has-text-white ml-4' onClick={handleCreateNewCampaignClick}>
                                    <MenuItem title="Create New Campaign" icon="fas fa-plus" />
                                </li>
                                <li className='my-5 has-text-white ml-4' onClick={handleShowAllCampignClick}>
                                    <MenuItem title="All Campaigns" icon="fas fa-check-circle" />
                                </li>
                                {/* <div className='my-5'>
                            <MenuItem title="Brand Applications" icon="fas fa-users" clickHandler={handleBrandApplicationsClick} />
                        </div> */}
                                {/* <div >
                                <MenuItem title="User Roles" icon="fas fa-users" clickHandler={handleUserRolesClick} />
                            </div> */}
                                {/* <MenuItem title="Settings" icon="fas fa-cog" /> */}
                            </div>
                        </ul>
                    </aside>
                    {/* </div> */}

                </div >

                <ul className="menu1   d-flex flex-row-reverse"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}>
                    {!isActive && (
                        <div className=' has-text-centered '>
                            <figure className="image is-32x32" >
                                <img src={logo} alt="Logo" />
                            </figure>


                            {!isActive && (
                                <div className='my-5 has-text-white ml-4 '>
                                    <MenuItem icon="fas fa-plus" clickHandler={handleCreateNewCampaignClick} style={{ marginLeft: "90px", marginTop: "700px" }} />
                                </div>
                            )}
                            {!isActive && (
                                <div className='my-5 has-text-white ml-4'>
                                    <MenuItem icon="fas fa-check-circle" clickHandler={handleShowAllCampignClick} style={{ merginButton: "20vw" }} />
                                </div>
                            )}
                            {/* {!isActive && (
                                <div className='my-5 has-text-white'>
                                    <MenuItem icon="fas fa-users" clickHandler={handleBrandApplicationsClick} />
                                </div>
                            )} */}
                            {/* {!isActive && (
                                <div className='has-text-white'>
                                    <MenuItem icon="fas fa-users" clickHandler={handleUserRolesClick} />
                                </div>

                            )} */}
                        </div>
                    )}
                    {/* <div >
                            <MenuItem title="Settings" icon="fas fa-cog" />
                            </div> */}
                </ul>

                {/* <ul onMouseEnter={handleMouseEnter}
                >
                    {!isActive && (
                        <li className="ml-5 my-5" style={{ cursor: "pointer" }} >
                            <span className="icon is-small has-text-success mr-1" >
                                <i className="fas fa-plus" style={{ marginTop: "30px", marginRight: "90px" }}></i>
                            </span>
                        </li>
                    )}
                    {!isActive && (
                        <li className="ml-5 my-5" style={{ cursor: "pointer" }}>
                            <span className="icon is-small has-text-success mr-1" >
                                <i className="fas fa-check-circle" style={{ marginTop: "30px", marginRight: "90px" }}></i>
                            </span>
                        </li>
                    )}
                    {!isActive && (
                        <li className="ml-5 my-2" style={{ cursor: "pointer" }}>
                            <span className="icon is-small has-text-success mr-1">
                                <i className="fas fa-users" style={{ marginRight: "90px" }}></i>
                            </span>
                        </li>
                    )}
                    {!isActive && (
                        <li className="ml-5 my-2" style={{ cursor: "pointer" }}>
                            <span className="icon is-small has-text-success" >
                                <i className="fas fa-users" style={{ marginRight: "90px" }} ></i>
                            </span>
                        </li>
                    )}
                    {!isActive && (
                        <li className="ml-5 my-2" style={{ cursor: "pointer", }}>

                            <span className="icon is-small has-text-success">
                                <i className="fas fa-cog" style={{ marginRight: "90px" }} ></i>
                            </span>

                        </li>
                    )}
                </ul> */}
            </div >

            {/* Main content */}
            < div className="column" >
                <div className="columns is-ancestor">
                    <div className="column  mt-5">

                        {showCreateCampaignForm ? (
                            // Render the Create Campaign form
                            <div className="is-child  has-text-centered" style={{ height: '100vh', width: '50vw', marginLeft: '250px' }}>
                                {/* <h1 className='subtitle mt-5'>Create New Campaign</h1> */}
                                <BrandCampaignForm />
                            </div>
                        ) : showAllCampaign ? (

                            <div className='subtitle' style={{ fontSize: '15px', marginLeft: "90px" }}>
                                <ApproveCampaignPageInternal />
                            </div>

                            // ) : showC1Table ? (
                            //     <div className='is-child box' style={{ overflowX: 'auto', maxHeight: '500px' }}>
                            //         {/* filters */}
                            //         <div className="filter-buttons my-4 ml-2">
                            //             <button className="mr-2" onClick={() => setFilterStatus('all')}>All</button>
                            //             <button className="mr-2" onClick={() => setFilterStatus('dispatch')}>Pending for Dispatch</button>
                            //             <button className="mr-2" onClick={() => setFilterStatus('selection')}>Pending for Selection</button>
                            //             <button className="mr-2" onClick={() => setFilterStatus('approval')}>Pending for Approval</button>
                            //         </div>

                            //         <div className='subtitle '>
                            //             <h1>c1</h1>

                            //         </div>
                            //         {/*  C1 Table code */}
                            //         {/* ... */}
                            //     </div>


                            // ) : showC2Table ? (
                            //     <div className='is-child box' style={{ overflowX: 'auto', maxHeight: '500px' }}>
                            //         {/* filters */}
                            //         <div className="filter-buttons my-4 ml-2">
                            //             <button className="mr-2" onClick={() => setFilterStatus('all')}>All</button>
                            //             <button className="mr-2" onClick={() => setFilterStatus('dispatch')}>Pending for Dispatch</button>
                            //             <button className="mr-2" onClick={() => setFilterStatus('selection')}>Pending for Selection</button>
                            //             <button className="mr-2" onClick={() => setFilterStatus('approval')}>Pending for Approval</button>
                            //         </div>
                            //         <div className='subtitle'>
                            //             <h1>c2</h1>
                            //             {/* c2 table */}
                            //         </div>
                            //     </div>
                            // ) : showC3Table ? (
                            //     <div className='is-child box' style={{ overflowX: 'auto', maxHeight: '500px' }}>
                            //         {/* filters */}
                            //         <div className="filter-buttons my-4 ml-2">
                            //             <button className="mr-2" onClick={() => setFilterStatus('all')}>All</button>
                            //             <button className="mr-2" onClick={() => setFilterStatus('dispatch')}>Pending for Dispatch</button>
                            //             <button className="mr-2" onClick={() => setFilterStatus('selection')}>Pending for Selection</button>
                            //             <button className="mr-2" onClick={() => setFilterStatus('approval')}>Pending for Approval</button>
                            //         </div>
                            //         <div className='subtitle'>
                            //             <h1>C3</h1>
                            //             {/* c3 table */}
                            //         </div>
                            //     </div>
                            //1o651InfluencerApplications
                        ) : showInfluencerApplications ? (
                            <div className='is-child box' style={{ overflowX: 'auto', overflowY: 'auto', width: '1060px' }}>
                                {/* filters */}
                                {/* <div className="filter-buttons my-4 mr-3">
                                    <button className="mr-2" onClick={() => setFilterStatus('all')}>All</button>
                                    <button className='mr-2' onClick={() => setFilterStatus('Pending Deliverables')}>Selected</button>
                                    <button className='mr-2' onClick={() => setFilterStatus('Pending Deliverables')}>Rejected</button>
                                    <button className='mr-2' onClick={() => setFilterStatus('Pending Deliverables')}>On-Hold</button>
                                </div> */}
                                <div className='subtitle' style={{ fontSize: '15px' }}>
                                    {/* <h1 className='underline'>Influencer Applications</h1> */}
                                    <BrandDashboard1 />
                                </div>

                            </div>
                        ) : showBrandApplications ? (
                            <div className='is-child box' style={{ overflowX: 'auto', overflowY: 'auto', width: '1060px' }}>
                                {/* filters */}
                                {/* <div className="filter-buttons my-4 mr-3">
                                    <button className="mr-2" onClick={() => setFilterStatus('all')}>All</button>
                                    <button className='mr-2' onClick={() => setFilterStatus('Pending Deliverables')}>Pending Deliverables</button>
                                </div> */}
                                <div className='subtitle' style={{ fontSize: '15px' }}>
                                    <h1 className='underline'>Brand Application</h1>
                                    <BrandTable1 />

                                </div>

                            </div>

                        ) : showShortlistedInfluencers ? (
                            <div className='is-child box has-text-centered' style={{ overflowX: 'auto', overflowY: 'auto', width: '1060px' }}>
                                <h1 className='subtitle mt-5 underline'>Shortlisted Influencers
                                </h1>
                                <div></div>
                            </div>
                        ) : showDeliverables ? (
                            <div className='is-child box has-text-centered' style={{ overflowX: 'auto', overflowY: 'auto', width: '1060px' }}>
                                <h1 className='subtitle mt-5 underline'>
                                </h1>
                                <InfluencerDeliverblesTable />
                                <div></div>
                            </div>
                        ) : showConformation ? (
                            <div className='is-child box has-text-centered' style={{ overflowX: 'auto', overflowY: 'auto', width: '1060px' }}>
                                <h1 className='subtitle mt-5 underline'>
                                </h1>
                                {/* <InfluencerConformationTable /> */}
                                <InfluencerConformationTableExecutive />
                                {/* <div>Influencer Conformation</div> */}
                            </div>
                            // showuserRoles
                        ) : showUserRoles ? (
                            <div className='is-child box has-text-centered'>
                                <div className='field'>
                                    <div className="control my-3 ">
                                        <button className="button is-primary is-rounded  is-focused is-medium is-fullwidth " onClick={handleInviteTeamMembersClick} >
                                            Invite Team Mambers
                                        </button>

                                    </div>
                                </div>
                                <h1 className='subtitle mt-5'>Choose Campaigns
                                </h1>
                                {/* Add your User Roles content here */}

                                <div className='form'>
                                    <div className='field'>
                                        <label className="C1 mr-5">
                                            <input type="radio" name="campaigns" className='is-rounded mr-2'
                                            /> C-1
                                        </label>
                                        <label className="C2 ml-5">
                                            <input type="radio" name="campaigns" className='is-rounded mr-2'
                                            /> C-2
                                        </label>
                                    </div>
                                    <div className='field'>
                                        <label className="C-3 mr-5">
                                            <input type="radio" name="campaigns" className='is-rounded mr-2'
                                            /> C-3
                                        </label>
                                        <label className="C4 ml-5">
                                            <input type="radio" name="campaigns" className='is-rounded mr-2'
                                            /> C-4
                                        </label>

                                    </div>
                                </div>


                            </div>
                        ) : showInviteForm ? (
                            // Display the invite form
                            <div className='is-child box has-text-centered'>
                                <h1 className='subtitle mt-5'>Invite form campaign
                                </h1>
                                <div className='form'>

                                    <div className='field '>
                                        <div className="control has-icons-left">
                                            <div className='colomn'>
                                                <input className="input is-rounded "
                                                    type="text"
                                                    placeholder="Email ID"
                                                    value={Email}
                                                    onChange={(e) => setemail(e.target.value)}
                                                />
                                                {Email && !hasValEmail && (
                                                    <small
                                                        className={`ml-1 mt-1  ${!hasValEmail && Email !== '' ? 'has-text-danger' : 'has-text-success'}`}>
                                                        {!hasValEmail && Email !== '' ? ('Enter a valid email address') : ''}
                                                    </small>
                                                )}
                                                <span className="icon is-small is-left">
                                                    <i className="fas fa-envelope"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    {/* mobile number */}
                                    <div className='field '>
                                        <div className="control has-icons-left ">
                                            <div className='colomn'>
                                                <input className="input  is-rounded "
                                                    type="text"
                                                    placeholder="Mobile Number"
                                                    value={MobileNumber}
                                                    onChange={(e) => setmobilenumber(e.target.value)}
                                                />

                                                {/* mobile number validation */}
                                                {MobileNumber && !hasValNumber && (
                                                    <div>
                                                        <small
                                                            className={`ml-1 mt-1  ${!hasValNumber && MobileNumber !== '' ? 'has-text-danger' : 'has-text-success'}`}>
                                                            {!hasValNumber && MobileNumber !== '' ? 'Please Enter valid Mobile number' : ''}
                                                        </small>
                                                    </div>
                                                )}

                                                <span className='icon is-small is-left'>
                                                    <i className='fas fa-phone'></i>
                                                </span>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="field">
                                        <div className="control">

                                            <button className="button is-primary is-rounded  is-focused is-medium is-fullwidth " disabled={!MobileNumber || !Email || !hasValNumber || !hasValEmail}>
                                                SIGN UP
                                            </button>

                                        </div>
                                    </div>
                                </div>
                            </div>


                        ) : (
                            <div className='subtitle' style={{ fontSize: '15px', marginLeft: "50px" }}>
                                <ApproveCampaignPageInternal />

                            </div>
                            // ) : isActive ? (
                            //     <div className="is-child box" style={{ overflowX: 'auto', width: "1000px" }} >
                            //         <ApproveCampaignPageInternal />
                            //     </div>
                        )}


                    </div>
                </div>
            </ div>

        </nav >
    )
}
function MenuItem({ title, icon, clickHandler }) {
    return (
        <li className="ml-2 my-2" style={{ cursor: "pointer" }} onClick={clickHandler}>
            <span className="icon is-small has-text-success mr-1">
                <i className={icon} style={{ marginRight: "50px" }}></i>
            </span>
            <span style={{ marginLeft: "-25px" }}>{title}</span>
        </li>
    );
}
export default ExecutiveDashboard
