// SignIn.js

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { login } from '../components/api2';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../logo192.png';
const SignInBrand = () => {
    const [Email, setEmail] = useState("");
    const [Password, setPassword] = useState("");
    const [brandName, setBrandName] = useState("");
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword); // Toggle showPassword state
    };

    // const [credentials, setCredentials] = useState({
    //     email: '',
    //     password: ''
    // });
    const navigate = useNavigate();

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setCredentials({ ...credentials, [name]: value });
    // };

    // const setLoggedIn = (isLoggedIn) => {
    //     localStorage.setItem('isLoggedIn', isLoggedIn);
    // };

    // Helper function to get logged-in state from local storage
    // const getLoggedIn = () => {
    //     const isLoggedIn = localStorage.getItem('isLoggedIn');
    //     return isLoggedIn === 'true';
    // };

    // useEffect(() => {
    //     const isLoggedIn = getLoggedIn();
    //     if (isLoggedIn) {
    //         navigate("/admin-dashboard", { replace: true });
    //     }
    // }, [navigate]);



    const handlesignin = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const res = await login({ email: Email, password: Password, brandName: brandName });
            if (!res.error) {
                // Set logged-in state to true
                navigate("/brand-dashboard", { replace: true, state: { brandName: res.brandName } });
            } else {
                toast.error(res.error);
            }
        } catch (err) {
            // Handle error
        }
        finally {
            setLoading(false);
        }
    };

    return (
        <div className='imgfull'>
            <div className='container1 has-text-centered mt-5'>
                <div className='logo-card'>
                    <figure className="image is-64x64">
                        <img src={logo} alt="Logo" />
                    </figure>
                </div>
                <div className='container'>
                    <h1 className='title mt-3 has-text-white'>Sign In to Your Brand Dashboard</h1>
                    <form>
                        <div className='my-2   has-text-left my-input'>
                            <label className='label has-text-white'>Your Business Email Registered With hobo</label>
                        </div>
                        <div className='field'>

                            <div className='control has-icons-left my-input'>
                                <input
                                    className='input is-rounded '
                                    type='text'
                                    placeholder='Email ID'
                                    name='email'
                                    value={Email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <span className="icon is-small is-left">
                                    <i className="fas fa-envelope"></i>
                                </span>
                            </div>
                        </div>
                        <div className='my-2 has-text-left my-input'>
                            <label className='label has-text-white'>Password</label>
                        </div>
                        <div className='field'>
                            <div className="control has-icons-left has-icons-right is-flex my-input">
                                <input
                                    className="input is-rounded"
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder="Password"
                                    value={Password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <span className="icon is-small is-left">
                                    <i className="fas fa-key"></i>
                                </span>
                                <span className="icon is-small is-right" onClick={togglePasswordVisibility} style={{ cursor: 'pointer', 'pointer-events': 'all' }}>
                                    <i className={`fas fa-eye${showPassword ? '-slash' : ''}`}></i>
                                </span>
                            </div>
                        </div>
                        <div className='Link mb-3'>
                            <Link to='/forget-password'>
                                <p className='has-text-right my-input'>Forgot Your Password?</p>
                            </Link>
                        </div>
                        <div className='field'>
                            <div className='control my-input'>
                                <button
                                    className='button is-primary is-rounded is-focused is-medium is-fullwidth'
                                    disabled={!Email || !Password || loading}
                                    type='submit'
                                    onClick={handlesignin}
                                >
                                    {loading ? 'Loading...' : 'SIGN IN'}
                                </button>
                            </div>
                        </div>
                    </form>
                    <div className='is-flex has-text-left mr-5 mt-3 my-input ' style={{ marginLeft: "12rem" }}>
                        <p className="has-text-white ">Don't have an account?</p>
                        <Link to='/sign-up'>
                            <span className='has-text-weight-bold ml-2 '>Sign Up</span>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignInBrand;