import React, { useState, useEffect } from 'react';
import { Config } from '../.env.js';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
const InviteFormBrand = () => {
    const navigate = useNavigate();
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');
    const location = useLocation();
    const { brandName } = location.state;
    useEffect(() => {
        console.log('Location State:', location.state);
        console.log('Brand Name:', brandName);
    }, [location.state, brandName]);
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {

            // Make a POST request to the backend API
            const response = await fetch(`${Config.API_URL}/api/invite`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    userName,
                    email,
                    role,
                    brandName,
                })
            });
            console.log("brandName", brandName);
            if (!response.ok) {
                throw new Error('Failed to invite user');
            }

            // Reset form fields after successful invitation
            setUserName('');
            setEmail('');
            setRole('');

            // Optionally, you can show a success message to the user
            console.log('User invited successfully');
            toast.success("User invited successfully");
            //navigate("/userSignUp/:brandName", { replace: true, state: { brandName: brandName } });

        } catch (error) {
            console.error('Error inviting user:', error.message);
            // Optionally, you can show an error message to the user
        }
    };
    return (
        <div className='has-text-centered'>

            <form onSubmit={handleSubmit}>
                <div className='field'>
                    <label className='label' style={{ marginRight: "246px" }}>Name</label>
                    <div className='control'>
                        <input
                            className='input is-rounded'
                            type='text '
                            placeholder='User Name'
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                            style={{ width: '300px' }}
                        />

                    </div>
                </div>
                <div className='field'>
                    <label className='label ' style={{ marginRight: "246px" }}>Email Id</label>
                    <div className='control has-icons-right'>
                        <input
                            className='input is-rounded'
                            type='text'
                            placeholder='Email ID'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            style={{ width: '300px' }}
                        />
                    </div>
                </div>
                {/* Role selection dropdown */}
                <div className='field'>
                    <label className='label' style={{ marginRight: "271px" }}  >Role</label>
                    <div className='control'>

                        <div className='select' >
                            <select style={{ borderRadius: '20px', width: "300px" }}
                                value={role}
                                onChange={(e) => setRole(e.target.value)}
                            >
                                <option value=''>Select Role</option>
                                <option value='admin'>Admin</option>
                                <option value='lead'>Staff</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className='field'>
                    <div className='control'>
                        <button
                            className='button is-primary is-rounded ' style={{ width: "300px" }}
                            disabled={!userName || !email || !role}
                        >
                            Invite
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default InviteFormBrand;




