import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Config } from '../.env.js';
import logo from '../logo192.png';

const AllCamp = ({ campaign }) => {
    const navigate = useNavigate();

    const handleApply = () => {
        navigate('/application', { state: { campaign } });
    };
    // const handleApply = () => {
    //     // Navigate to the external URL directly
    //     window.location.href = 'https://influencer.hobo.video/';
    // };

    const handleApplyDeliverables = () => {
        navigate('/deliverables', { state: { campaign } });
    };

    const handleConfirmation = () => {
        navigate('/confirmation', { state: { campaign } });
    };

    const { brandName, followersCriteria, infDeliverables, cashValue, campaignName, uploadBrandLogo, approve, rejected } = campaign;

    let statusLabel = '';
    let statusColor = '';
    if (approve) {
        statusLabel = 'Approved Successfully';
        statusColor = 'green';
    } else if (rejected) {
        statusLabel = 'This campaign is Rejected';
        statusColor = 'red';
    } else {
        statusLabel = 'Pending';
        statusColor = 'orange';
    }

    const backgroundImageStyle = {
        backgroundImage: `url(${Config.API_URL}/uploads/${encodeURIComponent(uploadBrandLogo)})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '170px', // Adjust height as needed
        width: '450px', // Full width to match the button card below
        borderRadius: '6px',
        marginBottom: '-10px',
        marginLeft: '-15px',
        marginTop: '-13px',

    };
    const logoStyle = {
        position: 'absolute',
        bottom: '1px',
        left: '55px',
        width: '40px',
        height: '36px',
        top: "210px"
    };
    return (
        <div className="container" style={{ position: "relative" }}>
            <div className="card-content">
                <div className="box mt-6 ml-5" style={{ width: 475, height: 510, }}>
                    <div style={backgroundImageStyle}>
                        <img src={logo} alt="Logo" style={logoStyle} />
                    </div>
                    <br />
                    <div>
                        {/* Brand Name and Followers Criteria Section */}
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: "25px" }}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <span className="icon">
                                    <i className="fas fa-tag"></i>
                                </span>
                                <p className="has-text-grey-light" style={{ marginRight: "10px" }}>Brand Name</p>
                                <p className='has-text-weight-bold'>{brandName}</p>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                <span className="icon" style={{ marginRight: "125px" }}>
                                    <i className="fas fa-user-friends"></i> {/* Icon for followers */}
                                </span>
                                <p className="has-text-grey-light mr-5" >Followers Criteria</p>
                                <p className='has-text-weight-bold mr-5'>{followersCriteria}</p>
                            </div>
                        </div>

                        {/* deliverables and Give Products Worth Section */}
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: "65px" }}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <span className="icon">
                                    <i className="fas fa-tag"></i>
                                </span>
                                <p className="has-text-grey-light" style={{ marginRight: "10px" }}>Deliverables</p>
                                <p className='has-text-weight-bold'>{infDeliverables}</p>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                <span className="icon" style={{ marginRight: "130px" }}>
                                    <i className="fas fa-user-friends"></i> {/* Icon for followers */}
                                </span>
                                <p className="has-text-grey-light" style={{ marginRight: "10px" }}>Give Products Worth</p>
                                <p className='has-text-weight-bold mr-1'>{cashValue}</p>
                            </div>
                        </div>

                        <div className="mt-5" style={{ flexDirection: 'row', gap: '10px', }}>
                            <button onClick={handleApply} className="button is-small-custom  mt-3 ml-1 has-text-info " style={{ width: '140px', height: '30px' }}>Apply Now</button>
                            <button onClick={handleApplyDeliverables} className="button is-small-custom  mt-3 ml-1 has-text-info " style={{ width: '140px', height: '30px' }}>Deliverables</button>

                            <button onClick={handleConfirmation} className="button is-small-custom  mt-3 ml-1 has-text-info " style={{ width: '140px', height: '30px' }}>Confirmation</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default AllCamp;


//   <p>Campaign Title: {campaignName}</p>
//                         <p>Brand Name: {brandName}</p>
//                         <p>Followers Criteria: {followersCriteria}</p>
//                         <p>Deliverables: {infDeliverables}</p>
//                         <p>Give Product Worth Rs: {cashValue}</p>

// import React, { useState, useEffect } from 'react';
// import topImage from './img/download.jpeg';
// import logo from "./img/Logo (1).png";
// import { useNavigate } from 'react-router-dom';
// import { Config } from '../.env.js';
// import axios from 'axios';

// const CustomerCard = ({ campaign = "Hello", alignLeft, }) => {
//     // Styles for the top image card
//     const { campaignName, managers } = campaign;
//     const [product, setProduct] = useState(null);
//     // const { image } = product
//     useEffect(() => {
//         const fetchProduct = async () => {
//             try {
//                 const response = await axios.get(`${Config.API_URL}/api/product`, {
//                     params: { campaignName } // Send campaignName as parameter if needed
//                 });
//                 setProduct(response.data); // Update state with fetched data
//             } catch (error) {
//                 console.error("Error fetching product:", error);
//             }
//         };

//         fetchProduct();
//     }, [campaignName]);
//     const topImageStyle = {
//         backgroundImage: `url(${topImage})`,
//         backgroundSize: 'cover',
//         backgroundPosition: 'center',
//         height: '170px', // Adjust height as needed
//         width: '450px', // Full width to match the button card below
//         borderRadius: '6px',
//         marginBottom: '-10px',
//         marginLeft: '-15px',
//         marginTop: '-13px',

//     };
//     const logoStyle = {
//         position: 'absolute',
//         bottom: '100px',
//         left: '35px',
//         width: '40px',
//         height: '36px',
//         top: "300px"
//     };

//     const navigate = useNavigate();

//     const title = {
//         title: "Subtitle"
//     };
//     const brandName = {
//         brandName: "BrandName"
//     }

//     const followersCriteria = {
//         followersCriteria: "followersCriteria"
//     }
//     const deliverables = {
//         deliverables: "deliverables"
//     }
//     const GiveProductsWorth = {
//         GiveProductWorth: "Give Products Worth"
//     }
//     const dealtype = {
//         dealtype: "deal type"
//     }
//     const handleApply = () => {
//         navigate(`/campaignDetails`, { state: { campaignName } });
//     };

//     const handleApplyDeliverables = () => {
//         navigate(`/order-form`, { state: { campaignName } });

//     };

//     const handleConfirmation = () => {
//         navigate(`/refund-form`, { state: { campaignName } });
//     };

//     const handleOrders = () => {
//         navigate(`/order-table`, { state: { campaignName } });
//     };

//     return (
//         <div className='is-max-desktop'>
//             <div >
//                 {/* Button Card */}
//                 <div className="card-content">
//                     <div className="box mt-6 ml-5" style={{ width: 476, height: 550, }}>
//                         <div style={topImageStyle}>
//                             <img src={logo} alt="Logo" style={logoStyle} />
//                         </div>
//                         <br />
//                         <div>
//                             {/* Brand Name and Followers Criteria Section */}
//                             <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: "20px" }}>
//                                 <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
//                                     <span className="icon">
//                                         <i className="fas fa-tag"></i>
//                                     </span>
//                                     <p className="has-text-grey-light" style={{ marginRight: "10px" }}>Brand Name</p>
//                                     <p className='has-text-weight-bold'>{brandName.brandName}</p>
//                                 </div>

//                                 <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
//                                     <span className="icon" style={{ marginRight: "125px" }}>
//                                         <i className="fas fa-user-friends"></i> {/* Icon for followers */}
//                                     </span>
//                                     <p className="has-text-grey-light mr-5" >Followers Criteria</p>
//                                     <p className='has-text-weight-bold mr-5'>{followersCriteria.followersCriteria}</p>
//                                 </div>
//                             </div>

//                             {/* deliverables and Give Products Worth Section */}
//                             <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: "20px" }}>
//                                 <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
//                                     <span className="icon">
//                                         <i className="fas fa-tag"></i>
//                                     </span>
//                                     <p className="has-text-grey-light" style={{ marginRight: "10px" }}>Deliverables</p>
//                                     <p className='has-text-weight-bold'>{deliverables.deliverables}</p>
//                                 </div>

//                                 <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
//                                     <span className="icon" style={{ marginRight: "130px" }}>
//                                         <i className="fas fa-user-friends"></i> {/* Icon for followers */}
//                                     </span>
//                                     <p className="has-text-grey-light" style={{ marginRight: "10px" }}>Give Products Worth</p>
//                                     <p className='has-text-weight-bold mr-1'>{GiveProductsWorth.GiveProductWorth}</p>
//                                 </div>
//                             </div>

//                             <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: "20px" }}>
//                                 <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
//                                     <span className="icon">
//                                         <i className="fas fa-tag"></i>
//                                     </span>
//                                     <p className="has-text-grey-light" style={{ marginRight: "10px" }}>Deal Type</p>
//                                     <p className='has-text-weight-bold'>{dealtype.dealtype}</p>
//                                 </div>
//                             </div>


// <div style={{ flexDirection: 'row', gap: '10px', }}>
//     <button onClick={handleApply} className="button is-small-custom  mt-3 ml-1 has-text-info " style={{ width: '210px', height: '30px' }}>View Campaign</button>
//     <button onClick={handleApplyDeliverables} className="button is-small-custom  mt-3 ml-1 has-text-info " style={{ width: '210px', height: '30px' }}>View Order Form</button>

//     <button onClick={handleConfirmation} className="button is-small-custom  mt-2 ml-1 has-text-info " style={{ width: '210px', height: '30px' }}>View Refund Form</button>
//     <button onClick={handleOrders} className="button is-small-custom  mt-2 ml-1 has-text-info" style={{ width: '210px', height: '30px' }}>View Orders</button>

// </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div >
//     );
// }

// export default CustomerCard;