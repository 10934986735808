import React, { useState, useEffect } from 'react';
import { Config } from '../.env.js';
import { toast } from 'react-toastify';

const EditFormBrand = ({ closeModal, selectedUser }) => {
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');
    const [userId, setUserId] = useState('');

    useEffect(() => {
        if (selectedUser) {
            setUserName(selectedUser.userName);
            setEmail(selectedUser.email);
            setRole(selectedUser.role);
            setUserId(selectedUser._id); // Ensure the correct ID field is used
        }
    }, [selectedUser]);
    const handleDelete = async () => {
        if (!window.confirm('Are you sure you want to delete this user?')) {
            return;
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Selected User ID:', userId);

        try {
            const response = await fetch(`${Config.API_URL}/api/users/${userId}`, { // Use userId here
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    userName,
                    email,
                    role
                })
            });

            if (!response.ok) {
                throw new Error('Failed to update user');
            }

            toast.success('User updated successfully');
            closeModal();
        } catch (error) {
            console.error('Error updating user:', error.message);
        }
    };

    const handleCancelEdit = () => {
        closeModal();
    };

    return (
        <div className="modal is-active" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
            <div className="modal-content" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', borderRadius: '8px', width: '80%', maxWidth: '800px' }}>
                <button className="delete" aria-label="close" onClick={handleCancelEdit}></button>
                <div className="card">
                    <header className="card-header">
                        <p className="card-header-title">Edit User</p>
                    </header>
                    <div className="card-content">
                        <form onSubmit={handleSubmit}>
                            <div className='field'>
                                <label className='label has-text-left'>Name</label>
                                <div className='control'>
                                    <input
                                        className='input is-rounded'
                                        type='text'
                                        placeholder='User Name'
                                        value={userName}
                                        onChange={(e) => setUserName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='field'>
                                <label className='label has-text-left'>Email Id</label>
                                <div className='control'>
                                    <input
                                        className='input is-rounded'
                                        type='text'
                                        placeholder='Email ID'
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='field'>
                                <label className='label has-text-left'>Role</label>
                                <div className='control'>
                                    <div className='select' >
                                        <select
                                            style={{ borderRadius: '20px', width: "900px" }}
                                            value={role}
                                            onChange={(e) => setRole(e.target.value)}
                                        >
                                            <option value=''>Select Role</option>
                                            <option value='admin'>Admin</option>
                                            <option value='staff'>Staff</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className='field'>
                                <div className='control'>
                                    <button className='button is-primary is-rounded is-fullwidth' disabled={!userName || !email || !role}>Update</button>
                                </div>
                            </div>
                            <div className='field' style={{ marginTop: '10px' }}>
                                <div className='control'>
                                    <button
                                        className='button is-danger is-rounded is-fullwidth'
                                        onClick={handleDelete}
                                    >
                                        Delete
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditFormBrand;