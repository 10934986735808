import React, { useState, useEffect } from 'react';
import ApplicationPage from './AppicationPage';
import InfluencerconfirmationPage from './confirmationPage';
import InfDelverablesForm from './influencrDeliverables';
import ViewMore from './ViewMore';
import { Config } from '../.env.js';
import { useNavigate } from 'react-router-dom';
import ViewCampaign from './viewCampaign.js';
import logo from '../logo192.png';
import CampaignProgress from './campaignProgress'
const CampaignLiveLead = ({ campaign }) => {
    const navigate = useNavigate();
    const [isApplicationPageOpen, setIsApplicationPageOpen] = useState(false);
    const [isConfirmationPageOpen, setIsConfirmationPageOpen] = useState(false);
    const [isInfDelverablesFormOpen, setIsInfDelverablesFormOpen] = useState(false);
    const [isviewMoreOpen, setisViewMoreopen] = useState(false);
    const [isviewCampaignOpen, setisviewCampaignopen] = useState(false);
    const [isViewMoreHovered, setIsViewMoreHovered] = useState(false);
    const [isViewCampaignHovered, setIsViewCampaignHovered] = useState(false);
    const [zoomedIn, setZoomedIn] = useState(false);

    useEffect(() => {
        // Function to detect zoom level or screen width
        const detectZoom = () => {
            const isZoomedIn = window.devicePixelRatio > 1;
            setZoomedIn(isZoomedIn);
        };

        // Initial detection
        detectZoom();

        // Event listener for window resize
        window.addEventListener('resize', detectZoom);

        // Cleanup function
        return () => {
            window.removeEventListener('resize', detectZoom);
        };
    }, []);
    const handleApply = () => {
        setIsApplicationPageOpen(true);
    };

    const handleCloseApplication = () => {
        setIsApplicationPageOpen(false);
    };
    const handleApply1 = () => {
        setIsInfDelverablesFormOpen(true);
    }

    const handleviewCampaign = () => {
        const { campaignName } = campaign;
        navigate(`/view-campaignBrand/${campaignName}`)
        // Handle this case, such as displaying a message to the user

    };

    const handlecloseviewCampaign = () => {
        setisviewCampaignopen(false);
    }
    const handleCloseDelverable = () => {
        setIsInfDelverablesFormOpen(false);
    }
    const handleConfirmation = () => {
        setIsConfirmationPageOpen(true);
    };
    const handleCloseConfirmation = () => {
        setIsConfirmationPageOpen(false);
    };
    // const handleviewMore = () => {
    //     setisViewMoreopen(true);
    // };
    const handlecloseviewMore = () => {
        setisViewMoreopen(false);
    }
    if (!campaign) {
        return <div>No campaign data available</div>;
    }

    // const { brandName, followersCriteria, infDeliverables, cashValue, campaignName, uploadBrandLogo, approve, rejected } = campaign;


    const handleviewMore = () => {
        const { campaignName } = campaign; // Extract brandName from campaign
        navigate(`/tbllead/${campaignName}`);
        // Navigate to a new route
    };

    const topImageStyle = {
        backgroundImage: `url(${Config.API_URL}/uploads/${encodeURIComponent(campaign.uploadBrandLogo)})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '180px', // Adjust height as needed
        width: '450px', // Full width to match the button card below
        borderRadius: '6px',
        marginBottom: '-10px',
        marginLeft: '-15px',
        marginTop: '-13px',

    };
    const logoStyle = {
        position: 'absolute',
        bottom: '4px',
        left: '55px',
        width: '40px',
        height: '36px',
        top: "220px"
    };
    const { brandName, followersCriteria, infDeliverables, cashValue, campaignName, DealType, uploadBrandLogo, approve, rejected } = campaign;
    let statusLabel = '';
    let statusColor = '';
    if (approve) {
        statusLabel = 'Approved Successfully';
        statusColor = 'green';
    } else if (rejected) {
        statusLabel = 'This campaign is Rejected';
        statusColor = 'red';
    } else {
        statusLabel = 'Pending for Approval';
        statusColor = 'orange';
    }
    return (
        <div className='container' style={{ position: "relative" }}>
            <div className="container" style={{ position: "relative" }}>
                <div className="card-content">
                    <div className="box mt-6 ml-5" style={{ width: 475, height: 580, }}>
                        <div style={topImageStyle}>
                            <img src={logo} alt="Logo" style={logoStyle} />

                        </div>
                        <br />
                        <h1 className='subtitle'><b>Good Influencer Campaign</b></h1>
                        <div>
                            {/* Brand Name and Followers Criteria Section */}
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: "25px" }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <span className="icon">
                                        <i className="fas fa-tag"></i>
                                    </span>
                                    <p className="has-text-grey-light" style={{ marginRight: "10px" }}>Brand Name</p>
                                    <p className='has-text-weight-bold'>{brandName}</p>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                    <span className="icon" style={{ marginRight: "125px" }}>
                                        <i className="fas fa-user-friends"></i> {/* Icon for followers */}
                                    </span>
                                    <p className="has-text-grey-light mr-5" >Followers Criteria</p>
                                    <p className='has-text-weight-bold mr-5'>{followersCriteria}</p>
                                </div>
                            </div>

                            {/* deliverables and Give Products Worth Section */}
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: "25px" }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <span className="icon">
                                        <i className="fas fa-tag"></i>
                                    </span>
                                    <p className="has-text-grey-light" style={{ marginRight: "10px" }}>Deliverables</p>
                                    <p className='has-text-weight-bold'>{infDeliverables}</p>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                    <span className="icon" style={{ marginRight: "130px" }}>
                                        <i className="fas fa-user-friends"></i> {/* Icon for followers */}
                                    </span>
                                    <p className="has-text-grey-light" style={{ marginRight: "10px" }}>Give Products Worth</p>
                                    <p className='has-text-weight-bold mr-1'>{cashValue}</p>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: "25px" }}>
                                <span className="icon" style={{ marginRight: "130px" }}>
                                    <i className="fas fa-user-friends"></i> {/* Icon for followers */}
                                </span>
                                <p className="has-text-grey-light" style={{ marginRight: "90px" }}>Deal Type</p>
                                <p className='has-text-weight-bold mr-1'>{DealType}</p>
                            </div>

                            {isConfirmationPageOpen && (
                                <div className="modal is-active">
                                    <div className="modal-background" style={{ backgroundColor: 'white' }}></div>
                                    <div className="modal-content">
                                        <InfluencerconfirmationPage onClose={handleCloseConfirmation} campaign={campaign} />
                                    </div>
                                    <button className="modal-close is-large" aria-label="close" onClick={handleCloseConfirmation}></button>
                                </div>
                            )}
                            {/* <button onClick={handleApply} className="button is-medium mt-3 ml-1 has-text-info">Apply Now</button> */}
                            {/* <button onClick={handleApply1} className="button is-medium mt-3 ml-1 has-text-info">Deliverables</button> */}
                            {/* <button onClick={handleConfirmation} className="button is-medium mt-2 ml-1 has-text-info">Confirmation</button> */}
                            {/* <button onClick={handleviewMore} className="button is-medium mt-2 ml-1 has-text-info" > view Influencer</button>
                            <button onClick={handleviewCampaign} className="button is-medium mt-3 ml-1 has-text-info" > view Campaign </button> */}
                            <CampaignProgress campaign={campaign} />
                            <div style={{ flexDirection: 'row', gap: '10px', marginTop: '10px' }}>
                                <button onClick={handleviewCampaign} className="button is-small-custom has-text-info mr-3" style={{ width: '210px' }}>
                                    View Campaign
                                </button>
                                <button onClick={handleviewMore} className="button is-small-custom has-text-info" style={{ width: '210px' }}>
                                    View More
                                </button>
                            </div>

                            {/* <div style={{ gap: '4%', marginTop: '60%', position: "relative", display: 'flex', justifyContent: 'center', }}>

                                <button
                                    onClick={handleviewMore}
                                    className={`button is-small-custom is-rounded  ${isViewMoreHovered ? 'is-primary' : ''}`}
                                    onMouseEnter={() => setIsViewMoreHovered(true)}
                                    onMouseLeave={() => setIsViewMoreHovered(false)}
                                >
                                    View Influencer
                                </button>
                                <button
                                    onClick={handleviewCampaign}
                                    className={`button is-small-custom is-rounded ${isViewCampaignHovered ? 'is-primary' : ''}`}
                                    onMouseEnter={() => setIsViewCampaignHovered(true)}
                                    onMouseLeave={() => setIsViewCampaignHovered(false)}
                                >
                                    View Campaign
                                </button>
                            </div> */}

                            {isviewCampaignOpen && (
                                <div className="modal is-active" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                                    <div className="modal-content" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', borderRadius: '8px', width: '80%', maxWidth: '800px' }}>
                                        <button className="delete" aria-label="close" onClick={handlecloseviewCampaign}></button>
                                        <h2 className="title is-4">View Campaign</h2>
                                        <ViewCampaign onClose={handlecloseviewCampaign} campaign={campaign} brandName={brandName} />
                                    </div>
                                </div>
                            )}

                            {/* <div style={{ color: statusColor }}>{statusLabel}</div> */}
                            {/* <div className="status-label has-border-primary  " style={{ position: 'absolute', top: '76px', left: '50px', color: statusColor, borderRadius: "20px" }}>
                                {statusLabel}
                            </div> */}

                            {isApplicationPageOpen && (
                                <div className="modal is-active">
                                    <div className="modal-background" style={{ backgroundColor: 'white' }}></div>
                                    <div className="modal-content">
                                        <ApplicationPage onClose={handleCloseApplication} campaign={campaign} />
                                    </div>
                                    <button className="modal-close is-large" aria-label="close" onClick={handleCloseApplication}></button>
                                </div>
                            )}
                            {isConfirmationPageOpen && (
                                <div className="modal is-active">
                                    <div className="modal-background" style={{ backgroundColor: 'white' }}></div>
                                    <div className="modal-content">
                                        <InfluencerconfirmationPage onClose={handleCloseConfirmation} campaign={campaign} />
                                    </div>
                                    <button className="modal-close is-large" aria-label="close" onClick={handleCloseConfirmation}></button>
                                </div>
                            )}
                        </div>

                        <div>

                            {isInfDelverablesFormOpen && (
                                <div className="modal is-active">
                                    <div className="modal-background" style={{ backgroundColor: 'white' }}></div>
                                    <div className="modal-content">
                                        <InfDelverablesForm onClose={handleCloseDelverable} campaign={campaign} />
                                    </div>
                                    <button className="modal-close is-large" aria-label="close" onClick={handleCloseDelverable}></button>
                                </div>
                            )}
                            {/* {isviewMoreOpen && (
                                <div className="modal is-active" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                                    <div className="modal-content" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', borderRadius: '8px', width: '80%', maxWidth: '800px' }}>
                                        <button className="delete" aria-label="close" onClick={handlecloseviewMore}></button>
                                        <h2 className="title is-4">View Influencer</h2>
                                        <ViewMore onClose={handlecloseviewMore} campaign={campaign} brandName={brandName} />

                                    </div>
                                </div>


                            )} */}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default CampaignLiveLead;



