import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import CampaignLive from './CampaignLive';
import { getAllCampaigns } from '../components/campaignApi';
import CampaignLiveExecutive from './CampaignLiveExecutive';

const ITEMS_PER_PAGE = 10; // Changed to 2
const PAGES_DISPLAYED = 5; // Number of pages displayed at a time

const ApproveCampaignPageInternal = () => {
    const location = useLocation();
    const [campaigns, setCampaigns] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [filter, setFilter] = useState('all');
    const [loading, setLoading] = useState(true);
    const approvedCampaign = location.state ? location.state.approvedCampaign : null;
    const prevApprovedCampaigns = location.state ? location.state.prevApprovedCampaigns : [];

    useEffect(() => {
        const fetchCampaigns = async () => {
            try {
                setLoading(true);
                const allCampaigns = await getAllCampaigns();
                // Filter campaigns based on the logged-in brand name
                setCampaigns(allCampaigns.reverse());
            } catch (error) {
                console.error('Error fetching campaigns:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchCampaigns();
    }, []);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleFilterChange = (filterValue) => {
        setFilter(filterValue);
        setCurrentPage(1); // Reset page when filter changes
    };

    // Filter campaigns based on the selected filter
    const filteredCampaigns = campaigns.filter((campaign) => {
        if (filter === 'all') return true;
        if (filter === 'approved') return campaign.approve;
        if (filter === 'pending') return !campaign.approve && !campaign.rejected;
        if (filter === 'rejected') return campaign.rejected;
        // if (filter === 'onHold') return !campaign.approved && !campaign.rejected;
        return true;
    });

    // Calculate total number of pages
    const totalPages = Math.ceil(campaigns.length / ITEMS_PER_PAGE);

    // Calculate the range of visible pages, handling edge cases to ensure valid range
    const startPage = Math.max(
        1,
        Math.min(currentPage - Math.floor(PAGES_DISPLAYED / 1), totalPages - PAGES_DISPLAYED + 1)
    );
    const endPage = Math.min(totalPages, startPage + PAGES_DISPLAYED - 1);

    // Slice the campaigns array to display only the relevant portion for the current page
    const paginatedCampaigns = filteredCampaigns.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        currentPage * ITEMS_PER_PAGE
    );

    return (
        <div className="dual-campaign-container mr-3">
            <div className="filter-buttons" style={{ display: 'flex', justifyContent: 'space-around', border: '1px solid #4a4a4a', padding: '7px', borderRadius: '8px', }}>
                {['all', 'approved', 'pending', 'rejected'].map((value) => (
                    <button
                        key={value}
                        className={filter === value ? 'active' : ''}
                        style={{
                            cursor: 'pointer',
                            border: 'none',
                            background: 'none',
                            color: '#4a4a4a',
                            fontWeight: filter === value ? 'bold' : 'normal',
                            transition: 'color 0.3s',
                        }}
                        onClick={() => handleFilterChange(value)}
                    >
                        {value.charAt(0).toUpperCase() + value.slice(1)}
                    </button>
                ))}
            </div>
            {loading ? (
                <div className="loading">Loading...</div>
            ) : (
                <div className="campaigns-container" >
                    {paginatedCampaigns.length === 0 ? (
                        <p>No {filter} campaigns available.</p>
                    ) : (
                        paginatedCampaigns.map((campaign, index) => (
                            <div key={index} className="campaign-item is-half">
                                <CampaignLiveExecutive key={index} campaign={campaign} alignLeft={index % 2 === 0} />
                            </div>
                        ))
                    )}
                </div>
            )}
            {/* Pagination controls */}
            {paginatedCampaigns.length > 0 && (
                <div className="pagination-container" role="navigation" aria-label="pagination">
                    {currentPage > 1 && (
                        <button className="pagination-previous" style={{ cursor: 'pointer' }} onClick={() => handlePageChange(currentPage - 1)}>Previous</button>
                    )}

                    {Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i).map((page) => (
                        <button key={page} className={`pagination-link ${page === currentPage ? 'is-current' : ''}`} aria-label={`Goto page ${page}`} style={{ cursor: 'pointer' }} onClick={() => handlePageChange(page)}>
                            {page}
                        </button>
                    ))}

                    {currentPage < totalPages && (
                        <button className="pagination-next" style={{ cursor: 'pointer' }} onClick={() => handlePageChange(currentPage + 1)}>Next</button>
                    )}
                </div>
            )}
        </div>
    );
};

export default ApproveCampaignPageInternal;
