import React from 'react';

const CampaignProgress = ({ campaign }) => {
    const getProgressColor = () => {
        if (campaign.approve) return 'green';
        if (campaign.rejected) return 'red';
        return 'gray';
    };

    return (
        <div style={{ width: '100%', padding: '16px', textAlign: 'center' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px', fontSize: '14px' }}>
                <span>Requested</span>
                <span>Pending for approval</span>
                <span>Approved</span>
            </div>

            <div style={{ position: 'relative', height: '4px', backgroundColor: '#e0e0e0', borderRadius: '4px', width: '100%' }}>
                <div
                    style={{
                        width: campaign.approve ? '100%' : campaign.rejected ? '0%' : '50%',
                        backgroundColor: getProgressColor(),
                        height: '100%',
                        borderRadius: '4px',
                        transition: 'width 0.3s ease'
                    }}
                />
                <div style={{
                    position: 'absolute',
                    top: '-6px',
                    left: '0%',
                    transform: 'translateX(-50%)',
                    width: '10px',
                    height: '10px',
                    backgroundColor: campaign.approve || campaign.rejected ? getProgressColor() : 'black',
                    borderRadius: '50%',
                }}></div>
                <div style={{
                    position: 'absolute',
                    top: '-6px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    width: '10px',
                    height: '10px',
                    backgroundColor: campaign.approve ? getProgressColor() : 'black',
                    borderRadius: '50%',
                }}></div>
                <div style={{
                    position: 'absolute',
                    top: '-6px',
                    left: '100%',
                    transform: 'translateX(-50%)',
                    width: '10px',
                    height: '10px',
                    backgroundColor: campaign.approve ? 'green' : '#e0e0e0',
                    borderRadius: '50%',
                }}></div>
            </div>
        </div>
    );
};

export default CampaignProgress;
